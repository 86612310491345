import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';

import BlogLayout from '../../components/BlogLayout';

const Days = () => {

  const language = "arabic"

  let saleMessage
  let saleProduct
  let salePrice
  let saleURL

  const [product, productName] = useState('')

  useEffect(() => {
    fetch(`https://api.mezzoguild.com/sales/` + language + `.json`)
      .then(response => response.json())
      .then(resultData => {
        productName(resultData)
        console.log(resultData)
      })
      
  }, [])

  saleMessage = product.message
  saleProduct = product.product
  salePrice = product.price
  saleURL = product.url

  return (
  <BlogLayout title="Faroese Days Of The Week" description="Want to know the days of the week in Faroese? Here are the days in Faroese and their pronunciation.">
    <h1>Faroese Days Of The Week</h1>
    <div className="table-wrap">
    <table>
      <thead>
        <tr>
          <th>Faroese</th>
          <th>Transliteration</th>
          <th>English</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>sunnudagur</td>
          <td>sunn-uuh-dae-wur</td>
          <td>Sunday</td>
        </tr>
        <tr>
          <td>mánadagur</td>
          <td>maah-nah-dae-wur</td>
          <td>Monday</td>
        </tr>
        <tr>
          <td>týsdagur</td>
          <td>tuysh-dae-wur</td>
          <td>Tuesday</td>
        </tr>
        <tr>
          <td>mikudagur / ónsdagur</td>
          <td>mee-kuu-dae-wur / ons-dae-wur</td>
          <td>Wednesday</td>
        </tr>
        <tr>
          <td>hósdagur / tórsdagur</td>
          <td>hoes-dae-wur / toas-dae-wur</td>
          <td>Thursday</td>
        </tr>
        <tr>
          <td>fríggjadagur</td>
          <td>fruydj-ah-dae-wur</td>
          <td>Friday</td>
        </tr>
        <tr>
          <td>leygardagur</td>
          <td>lay-yar-dae-wur</td>
          <td>Saturday</td>
        </tr>
      </tbody>
    </table>
    </div>
    <div className="navigation">
    <Link to="/phrases/greetings/">Greetings</Link>
    </div>
    <h3>{saleProduct}</h3>
    <p dangerouslySetInnerHTML={{ __html: saleMessage }} />
    <p><small>{salePrice}</small></p>
    <a href={saleURL}>Check it out</a>
  </BlogLayout>
  )};

export default Days;
